import React from 'react';
import Layout from "../components/Layout";
import {graphql} from "gatsby";
import Seo from "../components/Seo";
import Podcast from "../components/Podcast/Podcast";

const PodcastTemplate = ({ data, location }) => {
  const {
    prismicPodcast: { data: page },
  } = data
  const {
    allPrismicCategory: { edges: category },
  } = data
  if (!page) return null
  const {
    meta_title: metaTitle,
    meta_description: metaDescription,
  } = page
  return (
    <Layout location={location}>
      <Seo
        pathname={location.pathname}
        title={metaTitle || page.title.text}
        description={metaDescription}
      />
      <Podcast category={category} page={page} location={location}/>
    </Layout>
  );
};

export default PodcastTemplate;

export const query = graphql`
  query PodcastsBySlug($uid: String!) {
    allPrismicCategory {
      edges {
        node {
          uid
          data {
            name
          }
        }
      }
    }
    prismicPodcast(uid: { eq: $uid }) {
      data {
      content {
        text
        html
      }
      subtitle {
        text
        html
      }
      episodes {
        episode_link {
          url
        }
        youtube_link {
          url
        }
        episode_podcast {
          url
        }
        episode_text {
          text
          html
        }
        podcast_date
        apple_podcast_link {
          url
        }
        spotify_link {
          url
        }
        episode_title {
          text
        }
      }
      meta_description
      meta_title
      series_image {
        alt
        copyright
        url
        gatsbyImageData
      }
      title {
        text
        html
      }
    }
  }
}
`
