import React, {useEffect, useState} from 'react';
import {MainBackground} from "../MainBackground";
import styled from "styled-components";
import {Container} from "../Container";
import {GatsbyImage} from "gatsby-plugin-image";
import Episodes from "./Episodes";
import * as PropTypes from "prop-types";
import OutsideClick from "../OutsideClick";

OutsideClick.propTypes = {children: PropTypes.node};
const Podcast = ({page, category}) => {
  const [episodes] = useState(page.episodes.sort((a, b) => {
    return new Date(b.podcast_date) - new Date(a.podcast_date);
  }));
  const [episodesFiltered, setEpisodesFiltered] = useState(episodes);
  const [menu, setMenu] =  useState(false);
  const [date, setDate] =  useState(false);
  const [defaultValue, setDefaultValue] = useState('Everything');
  const [defaultValueDate, setDefaultValueDate] = useState('Latest podcasts');
  return (
    <>
      <style>
        @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap')
      </style>
      <MainBackground type={"violet"} container>
        <Wrapper>
          <Container>
            <TextWrapper>
              <h1>Podcasts</h1>
              <h3>{page.title.text}</h3>
              <h4>{page.subtitle.text}</h4>
            </TextWrapper>
            <PodcastContentWrapper>
              <Image>
                <GatsbyImage image={page.series_image?.gatsbyImageData} alt={"preview"} />
              </Image>
              <PodcastContent dangerouslySetInnerHTML={{ __html: page.content.html }}/>
            </PodcastContentWrapper>
          </Container>
          <SelectsWrapper>
            <Container>
              <Selects>
                <OutsideClick onToggle={() => setDate(false)}>
                  <CategoryList>
                    <p onClick={() => setDate(true)}>{defaultValueDate}</p>
                    {date && <ul>
                      <li onClick={() => {
                        setDefaultValueDate('Latest podcasts');
                        setDate(false)
                        setEpisodesFiltered(episodes.sort((a, b) => {
                          return new Date(b.podcast_date) - new Date(a.podcast_date);
                        }).filter((item, index) => index < 10));
                      }}>Latest podcasts</li>
                      <li onClick={() => {
                        setDefaultValueDate('All podcasts');
                        setDate(false)
                        setEpisodesFiltered(episodes.sort((a, b) => {
                          return new Date(b.podcast_date) - new Date(a.podcast_date);
                        }));
                      }}>All podcasts</li>
                      <li onClick={() => {
                        setDefaultValueDate('2024 podcasts');
                        setDate(false)
                        setEpisodesFiltered(episodes);
                        const newEpisodes = episodes.filter(episode => episode.podcast_date.split('-')[0] === '2024').sort((a, b) => {
                          return new Date(b.podcast_date) - new Date(a.podcast_date);
                        });
                        setEpisodesFiltered(newEpisodes);
                      }}>2024 podcasts</li>
                    </ul>}
                  </CategoryList>
                </OutsideClick>
                <OutsideClick onToggle={() => setMenu(false)}>
                  <CategoryList>
                    <p onClick={() => setMenu(true)}>{defaultValue}</p>
                    {menu && <ul>
                      <li onClick={() => {
                        setDefaultValue('Everything');
                        setMenu(false)
                        setEpisodesFiltered(episodes.sort((a, b) => {
                          return new Date(b.podcast_date) - new Date(a.podcast_date);
                        }));
                      }}>Everything</li>
                      {category.map(item => (<li onClick={() => {
                        setDefaultValue(item.node.data.name)
                        setMenu(false);
                        const newEpisodes = episodes.filter(episode => episode.episode_link.url.split('/')[episode.episode_link.url.split('/').length -1] === item.node.uid).sort((a, b) => {
                          return new Date(b.podcast_date) - new Date(a.podcast_date);
                        })
                        setEpisodesFiltered(newEpisodes);
                      }}>{item.node.data.name}</li>))}
                    </ul>}
                  </CategoryList>
                </OutsideClick>
              </Selects>
            </Container>
          </SelectsWrapper>
          <EpisodeWrapper>
            <Container>
              <RichTextContainer>
                {episodesFiltered.map((item, index) => (<Episodes key={index} index={index} episode={item}/>))}
              </RichTextContainer>
            </Container>
          </EpisodeWrapper>
        </Wrapper>
      </MainBackground>
    </>
  );
};

export default Podcast;

const RichTextContainer = styled.div`
  & * {
    font-family: "Roboto", sans-serif!important;
  }
  img {
    max-width: 600px;
    display: block;
    margin: 0 auto;
  }
  h2 {
    text-transform: uppercase;
    color: rgb(64,136,245);
    margin-bottom: 23px;
  }
  p {
    font-weight: 500;
  }
  ul, ol {
    padding: 0;
    margin-bottom: 32px;
    list-style: inside;
  }
  ol li, ul li {
    line-height: 30px;
    font-size: 18px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .embed iframe {
    width: 600px;
    height: 350px;
    margin: 0 auto 20px;
    max-width: 100%;
    display: block;
  }
  @media (max-width: 1024px) {
    ul, ol {
      margin-bottom: 15px;
    }
    ol li, ul li {
      font-size: 15px;
      line-height: 25px;
    }
    h4, h5, h6 {
      margin-bottom: 15px;
      font-size: 15px;
    }
  }
  @media (max-width: 640px) {
    .embed iframe {
      height: 300px;
    }
  }
`
const EpisodeWrapper = styled.div`
  background: rgb(249,249,249);
  color: black;
  position: relative;
  padding-top: 16px;
  padding-bottom: 10px;
  &>svg {
    position: absolute;
    right: 0;
    max-width: 100%;
  }
`
const SelectsWrapper = styled.div`
  background: rgb(42,149,191);
  padding: 10px 0;
`
const Selects = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 450px) {
    &>div {
     width: 48%; 
    }
  }
`

const Wrapper = styled.section`
  position: relative;
  color: #fff;
`
const CategoryList = styled.section`
  padding: 5px 10px;
  min-width: 205px;
  position: relative;
  border: 1px solid white;
  &:hover {
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    background-color: #11364E;
  }
  p {
    margin-bottom: 0;
    cursor: pointer;
  }
  ul {
    padding: 5px 0;
    position: absolute;
    z-index: 11;
    min-width: 100%;
    background: rgb(42,149,191);
    left: 0;
    top: 41px;
    li {
      cursor: pointer;
      padding: 5px 10px;
    }
  }
  @media (max-width: 450px) {
    min-width: auto;
  }
`
const PodcastContentWrapper = styled.section`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`
const PodcastContent = styled.section`
  max-width: 550px;
  p {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 1.5;
  }
  @media (max-width: 1024px) {
    max-width: 100%;
  }
`

const TextWrapper = styled.div`
  margin-bottom: 20px;

  h1 {
    line-height: calc(78 / 64);
    font-size: 26px;
    color: #fff;
    margin-bottom: 5px;
    text-transform: uppercase;

    @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
      font-size: 39px;
    }
  }
  h3 {
    color: #fff;
  }
  p {
    font-size: 12px;
    font-weight: bold;
    color: #999;
    line-height: 16px;
  }
  a {
    color: #fff;
  }
`
const Image = styled.div`
  display: flex;
  height: 240px;
  max-width: 240px;
  margin-right: 20px;
  .gatsby-image-wrapper {
    width: 100%;
  }
  @media (max-width: 1024px) {
    display: none;
  }
`